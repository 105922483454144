

























































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DataResponseCalendar } from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { RequestUpdateClosePeriod } from "@/models/interface/settings.interface";
import { assetsServices } from "@/services/assets.service";
import { settingsServices } from "@/services/settings.service";
import { LabelInValue } from "@/types";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormState = {
  period: LabelInValue | undefined;
  status: string;
};

@Component
export default class ClosePeriod extends Mixins(MNotification) {
  @Ref("form")
  form!: FormModel;

  loadingCalendar = false;
  dataCalendar: Option<DataResponseCalendar>[] = [];
  loadingOpen = false;
  loadingClose = false;
  disabled = {
    close: false,
  };
  calendarClosePeriodId = "";
  formState: FormState = {
    period: undefined,
    status: "",
  };
  validationSchema = {
    period: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    status: [],
  };

  mounted(): void {
    this.getListCalendar();
  }

  async validateForm(): Promise<void> {
    try {
      await this.form.validate();
      this.handleClose();
    } catch {
      this.showNotifWarning("notif_validation_error");
    }
  }

  async handleClose(): Promise<void> {
    try {
      const dataPost: RequestUpdateClosePeriod = {
        requestBody: [
          {
            calendarClosePeriodId: this.calendarClosePeriodId,
            closePeriod: true,
          },
        ],
      };
      this.loadingClose = true;
      await settingsServices.updateCalendarClosePeriod(dataPost);
      this.showNotifSuccess("notif_update_success");
      this.getListCalendar();
      this.form.resetFields();
    } catch {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loadingClose = false;
    }
  }

  onChangeCalendar(value: LabelInValue): void {
    const AR_MODULE = "AR";
    const data = this.dataCalendar.find(item => item.value === value.key);
    this.formState.status = "";
    if (data && data.meta) {
      const isClose = data.meta.calendarClosePeriods.find(
        item => item.modulName.toUpperCase() === AR_MODULE
      )?.closePeriod;
      this.formState.status =
        isClose === undefined ? "" : isClose ? "CLOSE" : "OPEN";
    }
    this.calendarClosePeriodId =
      this.dataCalendar
        .find(item => item.value === value.key)
        ?.meta?.calendarClosePeriods.find(data => data.modulName === AR_MODULE)
        ?.id ?? "";
    if (
      !this.dataCalendar
        .find(item => item.value === value.key)
        ?.meta?.calendarClosePeriods.find(data => data.modulName === AR_MODULE)
        ?.closePeriod
    )
      this.disabled.close = false;
    else this.disabled.close = true;
  }

  async getListCalendar(): Promise<void> {
    try {
      const response = await this.fetchCalendarList();
      this.dataCalendar = response;
    } catch {
      this.dataCalendar = [];
    }
  }

  async fetchCalendarList(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): Promise<Option<DataResponseCalendar>[]> {
    const EXCLUDE_OPTION = "ADJ";
    try {
      this.loadingCalendar = true;
      const response = await assetsServices.listCalendar(params);
      return response.data
        .filter(item => !item.month.toUpperCase().includes(EXCLUDE_OPTION))
        .map(item => ({
          label: item.month,
          value: item.month,
          key: item.month,
          meta: item,
        }));
    } catch {
      return [];
    } finally {
      this.loadingCalendar = false;
    }
  }

  onSearchCalendar(value?: string): void {
    debounce(async () => {
      const params = new RequestQueryParams();
      if (value) {
        params.search = new SearchBuilder()
          .push(["month", value], { like: "both" })
          .build();
      }
      const response = await this.fetchCalendarList(params);
      this.dataCalendar = response;
    });
  }
}
